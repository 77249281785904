/*******************************
     User Global Variables
*******************************/
@importGoogleFonts : false;
@fontName: 'SinkinSans-700Bold';
@bold: bold;

/*-------------------
    Site Colors
--------------------*/

@black: #000000;
@white: #FFFFFF;

@primaryColor: @white;
@pageBackground: @black;

/*-------------------
       Links
--------------------*/

@linkColor: @white;
@linkFontWeight: @bold;


@textColor: @white;

@hotpink: #FD20CB;

@font-face {
  font-family: 'Mitr-Regular';
  src: local('Mitr-Regular'), url(./fonts/Mitr-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-700Bold';
  src: local('SinkinSans-700Bold'), url(./fonts/SinkinSans-700Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-100Thin';
  src: local('SinkinSans-100Thin'), url(./fonts/SinkinSans-100Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-200xLight';
  src: local('SinkinSans-200XLight'), url(./fonts/SinkinSans-200XLight.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-300Light';
  src: local('SinkinSans-300Light'), url(./fonts/SinkinSans-300Light.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-400Regular';
  src: local('SinkinSans-400Regular'), url(./fonts/SinkinSans-400Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-500Medium';
  src: local('SinkinSans-500Medium'), url(./fonts/SinkinSans-500Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-600SemiBold';
  src: local('SinkinSans-600SemiBold'), url(./fonts/SinkinSans-600SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-700Bold';
  src: local('SinkinSans-700Bold'), url(./fonts/SinkinSans-700Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-800Black';
  src: local('SinkinSans-800Black'), url(./fonts/SinkinSans-800Black.ttf) format('truetype');
}

@font-face {
  font-family: 'SinkinSans-900XBlack';
  src: local('SinkinSans-900XBlack'), url(./fonts/SinkinSans-900XBlack.ttf) format('truetype');
}
